<template>
  <div class="row chart-row" :class="[bigRangeSize ? 'chart-row-short' : '']">
    <b-col
      md="3"
      v-if="!bigRangeSize"
      class="d-flex align-items-end justify-content-center"
      order="2"
      order-md="1"
    >
      <div class="dataCard">
        <div class="row">
          <div class="col-md-8 col-8 pr-0">
            <div class="d-flex justify-content-between">
              <div class="Last-year-title py-2">{{ data[0].name }}</div>
              <span class="line"></span>
            </div>
          </div>
          <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
            <span class="Last-year-value">{{ data[0].value }} </span>
          </div>
        </div>
      </div>
    </b-col>
    <!-- slider -->
    <b-col
      :lg="bigRangeSize ? 12 : 6"
      class="col-md-6 d-flex justify-content-center"
      order="1"
      order-md="2"
    >
      <div>
        <div class="slider-wrapper">
          <round-slider
            :style="{
              '--handleColor':
                handleColor === 'default' ? '#f3f3f3' : handleColor,
            }"
            v-model="sliderValue1"
            start-angle="0"
            end-angle="+180"
            line-cap="round"
            :radius="sliderRadius"
            :width="sliderWidth"
            pathColor="#c9ced5"
            rangeColor="#536dfe"
            handleSize="+15"
            :step="step"
            :editableTooltip="false"
            :change="sliderChange"
            :tooltipFormat="
              (val) => {
                return `<div style='margin-top:-75px'>
                            <div style='font-family: ProximaNovaRegular;font-size: 24px;color: #9aafd4'>${sliderName}</div>
                            <div style='font-family: ProximaNovaBOld;font-size: 28px;color: #222a37'>${val.value} % </div>
                        </div>`;
              }
            "
          />
        </div>
        <div v-if="bigRangeSize" class="d-flex justify-content-center mt-5">
          <div class="dataCard" :class="[bigRangeSize ? 'middleDatacard' : '']">
            <div class="row">
              <div class="col-8 pr-0">
                <div class="d-flex justify-content-around">
                  <div class="Last-year-title py-2">{{ data[0].name }}</div>
                  <span class="line"></span>
                </div>
              </div>
              <div class="col-4 pl-0 d-flex align-items-center">
                <span class="Last-year-value">{{ data[0].value }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-col>
    <b-col
      md="3"
      v-if="!bigRangeSize"
      class="d-flex align-items-end justify-content-center"
      order="3"
      order-md="3"
    >
      <div class="dataCard">
        <div class="row">
          <div class="col-md-8 col-8 pr-0">
            <div class="d-flex justify-content-between">
              <div class="Last-year-title py-2">{{ data[1].name }}</div>
              <span class="line"></span>
            </div>
          </div>
          <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
            <span class="Last-year-value">{{ data[1].value }}</span>
          </div>
        </div>
      </div>
    </b-col>
  </div>
</template>
<script>
import RoundSlider from "vue-round-slider";
export default {
  name: "SalesGrowth",
  components: {
    RoundSlider,
  },
  props: {
    sliderValue: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    bigRangeSize: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    handleColor:{
      type: String,
      default: 'default'
    },
    sliderName:{
      type: String,
      default:"Sales Growth",
    }
  },
  computed: {
    sliderRadius() {
      const extraSmall = window.matchMedia(
        "only screen and (max-width: 400px)"
      );
      if (extraSmall.matches) {
        return "120";
      }
      const isMobile = window.matchMedia("only screen and (max-width: 576px)");
      if (isMobile.matches) {
        return "150";
      }
      if (this.bigRangeSize) {
        return "180";
      } else {
        return "200";
      }
    },
    sliderWidth() {
      const extraSmall = window.matchMedia(
        "only screen and (max-width: 400px)"
      );
      if (extraSmall.matches) {
        return "20";
      }
      const isMobile = window.matchMedia("only screen and (max-width: 576px)");
      if (isMobile.matches) {
        return "25";
      } else {
        return "35";
      }
    },
  },
  data() {
    return {
      sliderValue1: 0,
    };
  },
  methods: {
    sliderChange(e) {
      this.$emit("sliderChangeHandle", e.value);
    },
  },
  beforeUpdate(){
      this.sliderValue1 = this.sliderValue;
  },
  created() {
    this.sliderValue1 = this.sliderValue;
  },
};
</script>

<style scoped>
.chart-row {
  margin-top: 100px;
}
.chart-row-short {
  margin-top: 70px;
}
.dataCard {
  max-width: 300px;
  width: 100%;
  min-height: 55px;
  margin-bottom: 100px;
  padding: 13px 9px 13px 15px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
}

.dataCard a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.middleDatacard {
  margin-bottom: 31px;
}

.slider-wrapper {
  height: 240px;
}

.Last-year-title {
  font-family: ProximaNovaRegular;
  color: #727988;
  font-size: 16px;
}

.line {
  width: 1px;
  margin: 0 11px;
  border: solid 1px #c6ccd6;
}

.Last-year-value {
  font-family: ProximaNovaSemiBold;
  font-size: 16px;
  font-weight: 600;
  color: #050505;
}

@media screen and (max-width: 768px) {
  .chart-row {
    margin-top: 30px;
  }
  .dataCard {
    margin-bottom: 30px;
  }
}
</style>
<style>
.rs-handle.rs-move {
  border: 11px solid #fff;
}
.rs-handle {
  background-color: var(--handleColor) !important;
}
.rs-handle.rs-focus{
  border: 11px solid #fff
}
</style>
