import Vue from "vue";
Vue.mixin({
    methods: {
        rearrangeArray(arr, n) {
            arr.sort((a, b) => {
                return a.value - b.value;
            });
            let temtArr = new Array(n);
            let ArrIndex = 0;
            for (var i = 0, j = n - 1; i < n / 2 || j > n / 2; i++, j--) {
                if (ArrIndex < n) {
                    temtArr[ArrIndex] = arr[i];
                    ArrIndex++;
                }
                if (ArrIndex < n) {
                    temtArr[ArrIndex] = arr[j];
                    ArrIndex++;
                }
            }
            for (i = 0; i < n; i++) {
                arr[i] = temtArr[i];
            }
            return arr
        },
    },
});